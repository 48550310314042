import { OnInit, Component } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { UtilityService } from 'src/app/core/services/utility.service';
import { Campaign } from 'src/app/models/campaign';
import { StatisticsModel } from 'src/app/models/statistics';

@Component({
  selector: 'app-view-stats',
  templateUrl: './stats-campaign.component.html',
  styleUrls: ['./stats-campaign.component.css']
})
export class StatsCampaignComponent extends StatisticsModel implements OnInit {
  public campaign: Campaign;

  constructor(public utilityService: UtilityService, protected ref: NbDialogRef<StatsCampaignComponent>) {
    super();
  }

  public ngOnInit() {}

  public close(): void {
    this.ref.close();
  }

  /**************************************************************/

  public getAverageExecDuration(): string | boolean {
    return super.getAverageExecDuration(this.campaign.total_duration, this.campaign.executions);
  }

  /**************************************************************/
  public getAverageNotifications(): number {
    return super.getAverageValue(this.campaign.total_notifications, this.campaign.executions);
  }

  /**************************************************************/
  public convertUnixTime(epoch: number): string | boolean {
    return this.utilityService.convertUnixTime_global(epoch);
  }
}
