/* eslint-disable @typescript-eslint/dot-notation */
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-string-translation',
  templateUrl: './string-translation.component.html',
  styleUrls: ['./string-translation.component.css']
})
export class StringTranslationComponent implements OnInit {
  @Input() public language: Object;
  @Input() public xliff: Object;

  public languageDisplay: string;

  constructor() {}

  public ngOnInit(): void {
    this.languageDisplay = this.language['key'] + ' - ' + this.language['value'];
  }
}
