<nb-card accent="success" style="width: 70%; margin: auto">
  <nb-card-header>
    <div class="row">
      <div class="col-auto mr-auto">
        <nb-icon icon="search-outline"></nb-icon>
      </div>
      <div class="col-auto mr-auto">Campaign ID: {{ campaign.id }} Stats</div>
      <div class="col-auto"><button nbButton (click)="close()">X</button></div>
    </div>
  </nb-card-header>
  <nb-card-body>
    <h6 class="text-uppercase">Campaign Details</h6>
    <hr />
    <dl class="row small">
      <dt class="col-2">Campaign name:</dt>
      <dd class="col-10">{{ campaign.name }}</dd>
      <br />
      <dt class="col-2">Creator:</dt>
      <dd class="col-10">{{ campaign.created_by }}</dd>
      <br />
      <dt class="col-2">Time created:</dt>
      <dd class="col-10">{{ campaign.created_dttm * 1000 | date: 'medium' }}</dd>
      <br />
      <!--<dt class="col-2">Owner: </dt>
      <dd class="col-10">{{campaign.owner}}</dd>
      <br>
      <dt class="col-2">Purpose: </dt>
      <dd class="col-10">{{campaign.purpose}}</dd> -->
    </dl>
    <h6 class="text-uppercase">Stats</h6>
    <hr />
    <dl class="row small">
      <dt class="col-3">Total executions:</dt>
      <dd class="col-9">{{ campaign.executions }}</dd>
      <br />
      <dt class="col-3">Avg. execution time:</dt>
      <dd class="col-9">{{ getAverageExecDuration() }}</dd>
      <br />
      <dt class="col-3">Total notifications:</dt>
      <dd class="col-9">{{ campaign.total_notifications }}</dd>
      <br />
      <dt class="col-3">Avg. notifications per execution:</dt>
      <dd class="col-9">{{ getAverageNotifications() }}</dd>
      <br />
      <dt class="col-3">Last campaign run:</dt>
      <dd class="col-9">{{ campaign.last_run_time * 1000 | date: 'medium' }}</dd>
    </dl>
  </nb-card-body>
  <nb-card-footer>
    <div class="d-flex flex-row-reverse">
      <div class="p-2">
        <button nbButton status="info" (click)="close()">Close</button>
      </div>
    </div>
  </nb-card-footer>
</nb-card>
