<nb-card
  accent="success"
  style="
    width: 90%;
    height: 90%;
    min-width: 700px;
    min-height: 475px;
    max-height: 800px;
    max-width: 1000px;
    margin: auto;
  "
>
  <nb-card-header>
    <div class="row">
      <div class="col-auto mr-auto">
        <nb-icon icon="search-outline"></nb-icon>
      </div>
      <div class="col-auto mr-auto">View String</div>
      <div class="col-auto">
        <button nbButton (click)="close()">X</button>
      </div>
    </div>
  </nb-card-header>
  <nb-card-body>
    <div class="wrapper">
      <div class="overlay" *ngIf="showSpinner">
        <div class="spinner-wrapper">
          <app-spinner></app-spinner>
        </div>
      </div>

      <div class="loaded-content" [class.blurred]="showSpinner">
        <h6 class="text-uppercase">String Details</h6>
        <dl class="row small">
          <dt class="col-2">ID</dt>
          <dd class="col-10">{{ xliff.id }}</dd>
          <dt class="col-2">Version</dt>
          <dd class="col-10">{{ xliff.version }}</dd>
          <dt class="col-2">Source</dt>
          <dd class="col-10">{{ xliff.source }}</dd>
          <dt class="col-2">Note</dt>
          <dd class="col-10">{{ xliff.note }}</dd>
          <dt class="col-2">Created by</dt>
          <dd class="col-10">{{ xliff.created_by }}</dd>
          <dt class="col-2">Created on</dt>
          <dd class="col-10">{{ utility.convertUnixTime_global(xliff.created_ts) }}</dd>
          <dt class="col-2">Last updated by</dt>
          <dd class="col-10">{{ xliff.last_updated_by }}</dd>
          <dt class="col-2">Last updated on</dt>
          <dd class="col-10">{{ utility.convertUnixTime_global(xliff.last_updated_ts) }}</dd>
        </dl>
        <div class="row">
          <h6 class="text-uppercase">Translations</h6>
          <dl class="row small" *ngFor="let language of translationLanguages | keyvalue:descOrder">
            <app-string-translation 
            [language]="language" 
            [xliff]="xliff"
            ></app-string-translation>
          </dl>
        </div>
      </div>
    </div>
  </nb-card-body>
  <nb-card-footer>
    <div class="d-flex flex-row-reverse">
      <div class="p-2">
        <button nbButton status="info" (click)="close()">Close</button>
      </div>
    </div>
  </nb-card-footer>
</nb-card>
